.table td,
.table th {
  white-space: pre-line !important;
}

.modal-size {
  position: absolute;
  width: 66%;
  height: 60%;

  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  transform: translate(-50%, -50%);
}