.border-none {
  border: none !important;
}
.mongo-title {
  position: absolute !important;
}
.gradient-text-dashboard-Id {
  font-weight: 900;
  background: -webkit-linear-gradient(#ad2897, #512199);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.variant-img-overlay {
  position: relative;
}
.variant-img-delete-icon {
  display: none;
  cursor: pointer;
}

.variant-img-overlay:hover {
  .variant-img-delete-icon {
    display: block;
    color: rgb(255, 255, 255);
    scale: 1.5;
  }
  img {
    filter: brightness(0.5);
  }
}
