.mongo {
  position: absolute;
  top: 5%;
  left: 25%;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: danger;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  z-index: 5;

  .mongo-title {
    position: absolute;
    top: -115%;
    color: #fff;
    height: 80px;
    width: 110px;
    clip-path: polygon(100% 0, 100% 38%, 66% 39%, 50% 48%, 34% 39%, 0 38%, 0 0);
    border-radius: 5%;
    background-color: #00000093;
    text-align: center;
    font-family: inherit;
    display: none;
  }
  &:hover .mongo-title {
    display: inline-block;
  }
}
